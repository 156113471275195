import { makeStyles } from '@material-ui/core/styles'
import type SvgIcon from '@material-ui/core/SvgIcon'
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@material-ui/core'
import Link from 'next/link'
import React from 'react'

export const useStyles = makeStyles((theme) => ({
  a: {
    textDecoration: 'none',
  },
  cardGrid: {
    maxWidth: 345,
  },
  media: {
    // height: 190,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid ' + theme.palette.divider,
  },
  mediaIcon: {
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    padding: '6px',
    color: 'white',
    margin: '2px 8px 0',
    position: 'absolute',
    top: '16px',
    right: '10px',
  },
  card: {
    height: '100%',
    minHeight: '200px',
  },
  cardActionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '60px 24px 24px',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  cardContent: {
    flex: 1,
  },
}))
export const HomeCard = ({
  title,
  bannerTitle,
  Icon,
  href,
  color,
}: {
  title: string
  bannerTitle: string
  Icon: typeof SvgIcon
  href?: string
  color: string
}) => {
  const classes = useStyles()
  const Inner = href ? CardActionArea : Box
  const cardContent = (
    <Card className={classes.card}>
      <Inner className={classes.cardActionArea}>
        <Box display={'flex'} flexDirection={'row'}>
          <div className={classes.mediaIcon} style={{ background: color }}>
            <Icon style={{ width: '100%', height: '100%' }} />
          </div>
          <Box mt={0}>
            <Typography align={'center'} variant={'h6'}>
              {bannerTitle}
            </Typography>
          </Box>
        </Box>
        <Typography align={'center'} variant={'subtitle1'} component={'span'}>
          {title}
        </Typography>
      </Inner>
    </Card>
  )
  return (
    <Grid item xs={12} sm={6} md={4} className={classes.cardGrid}>
      {href ? (
        <Link href={href} passHref>
          <a className={classes.a}>{cardContent}</a>
        </Link>
      ) : (
        cardContent
      )}
    </Grid>
  )
}
