import { isAfter, max } from 'date-fns'
import { ScienceSettings } from './interfaces/ScienceSettings'
import isUndefined from 'lodash/isUndefined'
import { parseDateWire, parseDateWireILTime } from '../lib/WireDateFormat'

export const isSciRegClosed = ({ categories }: ScienceSettings) => {
  const endDate = getMax(categories.map((c) => c.submissionDeadline))
  return isAfter(new Date(), endDate)
}

export const isSciEditClosed = ({ editUntilDate }: ScienceSettings) => {
  return editUntilDate && isAfter(new Date(), parseDateWire(editUntilDate))
}

export const isPassed = (date?: string) => {
  return date && isAfter(new Date(), parseDateWire(date))
}

export const isPassedIl = (dateStr?: string) => {
  return !!dateStr && isAfter(new Date(), parseDateWireILTime(dateStr))
}

export const isSciRegOpen = ({ submissionStartDate }: ScienceSettings) => {
  return isPassed(submissionStartDate)
}

export const getMax = (dates: (string | undefined)[]) =>
  max(dates.filter((v) => !isUndefined(v)).map((v) => parseDateWire(v as string)))
