import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import { isDefined } from 'ts-is-defined'
import { parseDateWire } from '../../../lib/WireDateFormat'
import { useCommonTranslations } from '../../common/Translation'
import { Form } from '../../../../generated/graphql'
import { useCurrentConferenceName } from '../../common/useCurrent'
import { HomeCard } from './HomeCard'
import Create from '@material-ui/icons/Create'
import blue from '@material-ui/core/colors/blue'
import React from 'react'
import { routes } from '../../../routes'
import { hasDatePassedIL } from '../../../lib/HasDatePassedIL'
import { TranslationType } from './TranslationType'
import { useConferenceRegisterContext } from '../../formeditor/FormRegisterContext'
import { ConferenceUserDataFragment } from '../../../data/fragments.generated'

export type RegLinkStatus = 'OPEN' | 'COMPLETE' | 'CLOSED' | 'NOT_OPEN'
type PartialForm = Pick<Form, 'id' | 'shortName' | 'settings'>

function getRegistrationStartDate(forms: PartialForm[]) {
  return minBy(forms.map((v) => v.settings.registrationStartDate).filter(isDefined), parseDateWire)
}

function getRegistrationEndDate(forms: PartialForm[]) {
  return maxBy(forms.map((v) => v.settings.registrationEndDate).filter(isDefined), parseDateWire)
}

const getRegLinkStatus = (forms: PartialForm[], user?: ConferenceUserDataFragment): RegLinkStatus => {
  const registrationStartDate = getRegistrationStartDate(forms)
  const registrationEndDate = getRegistrationEndDate(forms)

  if (user && forms.every((f) => user.forms.map((v) => v.id).includes(f.id))) {
    return 'COMPLETE'
  }
  if (hasDatePassedIL(registrationEndDate)) {
    return 'CLOSED'
  }
  if (registrationStartDate && !hasDatePassedIL(registrationStartDate)) {
    return 'NOT_OPEN'
  }
  return 'OPEN'
}
const makeRegTitle = (t: TranslationType, forms: PartialForm[], regLinkStatus: RegLinkStatus) => {
  switch (regLinkStatus) {
    case 'OPEN':
      return t.home.titles.register_title_open
    case 'COMPLETE':
      return t.home.titles.register_you_have_complete
    case 'CLOSED':
      return t.home.titles.register_title_closed
    case 'NOT_OPEN': {
      const registrationEndDate = getRegistrationEndDate(forms)
      return registrationEndDate
        ? t.home.titles.register_title_not_open(registrationEndDate)
        : t.home.titles.register_title_closed
    }
  }
}

function getRegRoute(
  status: RegLinkStatus,
  conferenceName: string,
  nonHiddenForms: PartialForm[]
): { href: string } | null {
  switch (status) {
    case 'OPEN': {
      if (nonHiddenForms.length > 1) {
        return routes.register.registrations(conferenceName)
      } else if (nonHiddenForms.length) {
        return routes.register.form(conferenceName, nonHiddenForms[0].shortName)
      } else {
        return null
      }
    }
    case 'NOT_OPEN':
    case 'COMPLETE':
    case 'CLOSED':
      return null
  }
}

export const HomeCardReg = () => {
  const t = useCommonTranslations()
  const conferenceName = useCurrentConferenceName()
  const { user: conferenceUser, conference } = useConferenceRegisterContext()

  const userAlreadyRegistered = !!conferenceUser?.forms.length
  const hideHomeRegLink = !userAlreadyRegistered && !!conference?.settings.display?.hideHomeRegLink
  const nonHiddenForms = conference?.forms.filter((v) => !v.settings.display?.hideInRegistrationLink) || []
  const regLinkStatus = getRegLinkStatus(nonHiddenForms, conferenceUser)
  const regRoute = getRegRoute(regLinkStatus, conferenceName, nonHiddenForms)

  if (!userAlreadyRegistered && (hideHomeRegLink || !nonHiddenForms.length)) {
    return null
  }

  return (
    <HomeCard
      title={makeRegTitle(t, nonHiddenForms, regLinkStatus)}
      bannerTitle={t.home.titles.register}
      Icon={Create}
      color={blue.A400}
      {...regRoute}
    />
  )
}
