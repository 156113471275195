import { TranslationType } from './TranslationType'
import { ScienceSettings } from '../../../data/interfaces/ScienceSettings'
import { isSciRegClosed } from '../../../data/dates'
import { hasDatePassedIL } from '../../../lib/HasDatePassedIL'
import { useCommonTranslations } from '../../common/Translation'
import { HomeCard } from './HomeCard'
import Subject from '@material-ui/icons/Subject'
import pink from '@material-ui/core/colors/pink'
import React from 'react'
import { routes } from '../../../routes'
import { useCurrentConferenceName } from '../../common/useCurrent'
import { useConferenceRegisterContext } from '../../formeditor/FormRegisterContext'
import { ConferenceUserDataFragment } from '../../../data/fragments.generated'

const getSciSubmitStatus = (
  scienceSettings: ScienceSettings,
  user?: ConferenceUserDataFragment
): SciLinkStatus => {
  const { submissionStartDate } = scienceSettings
  const hasAbstract = !!user?.scienceData // TODO that's not the way to check prob
  const isClosed = isSciRegClosed(scienceSettings)
  if (isClosed) {
    // if (!hasAbstract) {
    return 'CLOSED'
    // }
    // else {
    //   return 'CLOSED_TRACK'
    // }
  }
  const isOpen = hasDatePassedIL(submissionStartDate)
  if (isOpen) {
    return hasAbstract ? 'OPEN_HAS_ABSTRACT' : 'OPEN'
  } else {
    return 'NOT_OPEN'
  }
}
type SciLinkStatus = 'OPEN' | 'OPEN_HAS_ABSTRACT' | 'CLOSED_TRACK' | 'CLOSED' | 'NOT_OPEN'
const makeSciTitle = (t: TranslationType, status: SciLinkStatus, scienceSettings: ScienceSettings) => {
  const { submissionStartDate } = scienceSettings
  const titles = t.home.titles
  switch (status) {
    case 'OPEN':
      return titles.sci_title_open
    case 'OPEN_HAS_ABSTRACT':
      return titles.sci_title_open_has_abstracts
    case 'CLOSED_TRACK':
      return titles.sci_title_closed + ', ' + titles.sci_title_track
    case 'CLOSED':
      return titles.sci_title_closed
    case 'NOT_OPEN':
      return titles.sci_title_not_open(submissionStartDate)
  }
}
const getAbstractSubmitRoute = (status: SciLinkStatus, conferenceName: string) => {
  switch (status) {
    case 'OPEN':
      return routes.userScience.submit(conferenceName)
    case 'OPEN_HAS_ABSTRACT':
      return routes.userScience.list(conferenceName)
    case 'CLOSED_TRACK':
      return routes.userScience.list(conferenceName)
    case 'CLOSED':
    case 'NOT_OPEN':
  }
}
export const HomeCardScience = () => {
  const t = useCommonTranslations()
  const conferenceName = useCurrentConferenceName()
  const { user: conferenceUser, conference } = useConferenceRegisterContext()
  if (!conference) return null
  const { scienceSettings } = conference

  const sciSubmitStatus = getSciSubmitStatus(scienceSettings, conferenceUser)
  const abstractsSubmitRoute = getAbstractSubmitRoute(sciSubmitStatus, conferenceName)

  return scienceSettings.submissionStartDate ? (
    <HomeCard
      title={makeSciTitle(t, sciSubmitStatus, scienceSettings)}
      bannerTitle={t.home.titles.abstracts}
      Icon={Subject}
      color={pink['A200']}
      {...abstractsSubmitRoute}
    />
  ) : null
}
