import React from 'react'
import { LayoutType } from '../../src/components/common/LayoutType'
import { UserHome } from '../../src/components/register/home/UserHome'
import { RegisterLayout } from '../../src/components/formeditor/components/RegisterLayout'

export default function UserIndex() {
  return (
    <RegisterLayout maxWidth={'lg'} withoutPaper>
      <UserHome />
    </RegisterLayout>
  )
}

UserIndex.layoutType = LayoutType.USER
