import { useCommonTranslations } from '../../common/Translation'
import { useCurrentConferenceName } from '../../common/useCurrent'
import { HomeCard } from './HomeCard'
import Payment from '@material-ui/icons/Payment'
import { deepOrange } from '@material-ui/core/colors'
import React from 'react'
import { routes } from '../../../routes'
import { useConferenceRegisterContext } from '../../formeditor/FormRegisterContext'

export const HomeCardPay = () => {
  const t = useCommonTranslations()
  const conferenceName = useCurrentConferenceName()
  const { user: conferenceUser } = useConferenceRegisterContext()

  if (!conferenceUser?.hasDebt) return null

  return (
    <HomeCard
      title={t.home.titles.register_incomplete}
      bannerTitle={t.home.titles.pay}
      Icon={Payment}
      color={deepOrange[600]}
      {...routes.register.pay(conferenceName)}
    />
  )
}
