import { Box, Grid } from '@material-ui/core'
import Error from 'next/error'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { HomeCardReg } from './HomeCardReg'
import { HomeCardReview } from './HomeCardReview'
import { HomeCardScience } from './HomeCardScience'
import { HomeCardPay } from './HomeCardPay'
import { HomeCardSuperReview } from './HomeCardSuperReview'
import { useConferenceRegisterContext } from '../../formeditor/FormRegisterContext'

export const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'center',
  },
}))

export function UserHome() {
  const classes = useStyles()
  const { conference } = useConferenceRegisterContext()

  if (!conference) return <Error statusCode={404} />

  return (
    <Box mt={12} mb={8}>
      <Grid container spacing={4} className={classes.root}>
        <HomeCardPay />
        <HomeCardScience />
        <HomeCardSuperReview />
        <HomeCardReview />
        <HomeCardReg />
      </Grid>
    </Box>
  )
}
