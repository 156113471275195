import { useCommonTranslations } from '../../common/Translation'
import { ParticipantRoles } from '../../../data/interfaces/ParticipantRoles'
import { useCurrentConferenceName } from '../../common/useCurrent'
import { HomeCard } from './HomeCard'
import { routes } from '../../../routes'
import React from 'react'
import Settings from '@material-ui/icons/Settings'
import { deepPurple } from '@material-ui/core/colors'
import { useConferenceRegisterContext } from '../../formeditor/FormRegisterContext'

export const HomeCardSuperReview = () => {
  const t = useCommonTranslations()
  const { user: conferenceUser, conference } = useConferenceRegisterContext()
  const roles = conferenceUser?.roles as ParticipantRoles | undefined
  const conferenceName = useCurrentConferenceName()

  if (!conference) return null

  return roles?.superReviewer ? (
    <HomeCard
      title={t.home.titles.super_reviewer_title}
      bannerTitle={t.home.titles.super_reviewer}
      Icon={Settings}
      color={deepPurple['400']}
      {...routes.userScience.superList(conferenceName)}
    />
  ) : null
}
