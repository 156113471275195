import { useCommonTranslations } from '../../common/Translation'
import { useCurrentConferenceName } from '../../common/useCurrent'
import { HomeCard } from './HomeCard'
import StarHalf from '@material-ui/icons/StarHalf'
import teal from '@material-ui/core/colors/teal'
import { routes } from '../../../routes'
import React from 'react'
import { TranslationType } from './TranslationType'
import { ScienceSettings } from '../../../data/interfaces/ScienceSettings'
import { isAfter, isBefore, parseISO } from 'date-fns'
import { getMax } from '../../../data/dates'
import { useConferenceRegisterContext } from '../../formeditor/FormRegisterContext'

type LinkStatus = 'OPEN' | 'CLOSED' | 'NOT_OPEN'
const getReviewStatus = (scienceSettings: ScienceSettings): LinkStatus => {
  const { categories, reviewStartDate } = scienceSettings
  const reviewStarted = !isBefore(new Date(), parseISO(reviewStartDate))
  const endDate = getMax(categories.map((c) => c.reviewDeadline))
  const reviewEnded = isAfter(new Date(), endDate)

  if (reviewEnded) {
    return 'CLOSED'
  }
  if (reviewStarted) {
    return 'OPEN'
  }
  return 'NOT_OPEN'
}
const makeReviewTitle = (t: TranslationType, status: LinkStatus, scienceSettings: ScienceSettings) => {
  const { reviewStartDate } = scienceSettings
  const titles = t.home.titles
  switch (status) {
    case 'OPEN':
      return titles.review_title_open
    case 'CLOSED':
      return titles.review_title_closed
    case 'NOT_OPEN':
      return titles.review_title_not_open(reviewStartDate)
  }
}
export const HomeCardReview = () => {
  const t = useCommonTranslations()
  const conferenceName = useCurrentConferenceName()
  const { user: conferenceUser, conference } = useConferenceRegisterContext()

  if (!conference) return null
  const scienceSettings = conference.scienceSettings

  const reviewLinkStatus = getReviewStatus(scienceSettings)

  return conferenceUser?.reviewerId ? (
    <HomeCard
      title={makeReviewTitle(t, reviewLinkStatus, scienceSettings)}
      bannerTitle={t.home.titles.review}
      Icon={StarHalf}
      color={teal['A400']}
      {...(reviewLinkStatus === 'OPEN' ? routes.userScience.reviews(conferenceName) : {})}
    />
  ) : null
}
